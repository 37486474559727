<template>
  <div class="permission-manager">
    <b-card no-body class="shadow py-3 px-4">
      <b-card-header class="text-white">
        <span class="h2 font-weight-bold">Control Accesos del usuario: {{ selectedRole.nombres }}
          {{ selectedRole.apellidos }}
        </span>
      </b-card-header>
      <b-card-body>
        <!-- {{ permissionsMap }} -->
        <b-alert v-if="!selectedRole" show variant="info">
          Por favor selecciona una persona para ver y editar los permisos de
          acceso.
        </b-alert>

        <div v-else class="permissions-grid">
          <b-tabs content-class="mt-3" pill :vertical="responsive" nav-wrapper-class="w-5"
            active-nav-item-class="font-weight-bold text-uppercase bg-info">
            <b-tab v-for="(group) in data.registroJson.data" :key="group.groupId">
              <template #title>
                <span class="ml-2">
                  <b-img :src="group.icono" width="24" height="24" :alt="group.titulo">
                    {{ group.titulo }}
                  </b-img>
                  {{ group.titulo }}</span>
              </template>

              <div>
                <b-card v-for="(subGroup, subGroupIndex) in group.subGrupo" :key="subGroup.id" no-body
                  class="mb-3 w-100">
                  <b-card-header v-b-toggle="'collapse-' +
                    manipularTitulos(subGroup.titulo, subGroupIndex)
                    " class="d-flex justify-content-between" @click="colapsar(subGroupIndex)">
                    <span class="h5" v-if="
                      usuario.rol.nivel == 4 &&
                      subGroup.titulo == 'Super Admin'
                    ">Super Admin</span>
                    <span class="h5" v-else-if="
                      usuario.rol.nivel != 4 &&
                      subGroup.titulo == 'Super Admin'
                    ">Admin</span>
                    <span class="h5" v-else-if="subGroup.titulo != 'Super Admin'">{{ subGroup.titulo }}</span>

                    <i class="fa-solid fa-caret-down" v-if="!colapsado[subGroupIndex]"></i>
                    <i class="fa-solid fa-caret-up" v-else></i>
                  </b-card-header>

                  <b-collapse :id="'collapse-' +
                    manipularTitulos(subGroup.titulo, subGroupIndex)
                    " class="mt-2" v-for="(item, itemIndex) in subGroup.items" :key="item.id">
                    <b-card v-if="validarSuperAdmin(item.superAdmin)">
                      <b-list-group flush>
                        <b-list-group-item class="d-flex justify-content-between align-items-center">
                          <span>{{ item.titulo }}</span>
                          <div>
                            <vs-switch success :value="getPermissionStatus(item.ruta)"
                              @input="(val) => togglePermission(item.ruta, val)">
                              <template #on>
                                <i class="fa-solid fa-unlock"></i>
                              </template>
                              <template #off>
                                <i class="fa-solid fa-lock"></i>
                              </template>
                            </vs-switch>
                          </div>
                        </b-list-group-item>
                      </b-list-group>
                      <div v-if="item.subItems">
                        <b-button v-b-toggle="'collapse-' +
                          manipularTitulos(item.titulo, itemIndex)
                          " size="sm" class="mb-2">
                          Ver sub-vistas
                        </b-button>
                        <b-collapse :id="'collapse-' +
                          manipularTitulos(item.titulo, itemIndex)
                          " class="ml-5 mt-1 mb-2 w-75 border-left border-info">
                          <b-list-group-item v-for="(subItem) in item.subItems" :key="subItem.id"
                            class="d-flex justify-content-between align-items-center">
                            <span>{{ subItem.titulo }}</span>
                            <div>
                              <vs-switch success :value="getPermissionStatus(subItem.ruta)"
                                @input="(val) => togglePermission(subItem.ruta, val)">
                                <template #on>
                                  <i class="fa-solid fa-unlock"></i>
                                </template>
                                <template #off>
                                  <i class="fa-solid fa-lock"></i>
                                </template>
                              </vs-switch>
                            </div>
                          </b-list-group-item>
                        </b-collapse>
                      </div>
                    </b-card>
                  </b-collapse>
                </b-card>
              </div>
            </b-tab>
          </b-tabs>
        </div>

        <div class="d-flex justify-content-end mt-4">
          <b-button variant="success" class="mr-2" @click="saveData">
            <i class="fas fa-save mr-1"></i> Guardar Información
          </b-button>
          <b-button variant="danger" @click="cancelar">
            <i class="fas fa-times mr-1"></i> Cancelar
          </b-button>
        </div>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>

import Swal from "sweetalert2";

export default {
  data() {
    return {
      data: '',
      listaRoles: [],
      selectedRole: null,
      permissionsMap: {},
      originalPermissionsMap: {},
      widthWindow: window.innerWidth,
      colapsado: {},
      responsive: window.innerWidth > 600,
    };
  },

  async mounted() {
    this.usuario = JSON.parse(localStorage.getItem("setPersonaAct"));
    const personaId = this.$route.params.PersonaId;
    await this.fetchJsonData();
    await this.obtenerPersonas(personaId);


    //Evaluar constantemente el tamaño de la pantalla con un listener
    window.addEventListener("resize", this.updateWindowSize);
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.updateWindowSize);
  },

  methods: {

    async fetchJsonData() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "RegistrosJsonControlAccesos/GetLatestJson"
        });
        if (res && res.registroJson) {
          try {
            let cleanedJsonString = res.registroJson.replace(/\\"/g, '"').replace(/\\u([0-9a-fA-F]{4})/g, '\\u$1');
            const parsedJson = JSON.parse(cleanedJsonString);
            //console.log("ESTE ES EL REGISTROOOOOOOOOOOOOOOOO" + parsedJson)
            this.data = {
              id: res.id,
              fechaRegistro: res.fechaRegistro,
              registroJson: parsedJson
            };
          } catch (parseError) {
            this.data = null;
          }
        } else {
          this.data = null;
        }
      } catch (error) {
        this.data = null;
      }
    },

    createPermissionPath(...args) {
      // Eliminar duplicados y concatenar los segmentos
      return args.filter((seg, i, arr) => arr.indexOf(seg) === i).join('/');
    },

    updateSelectedRole(role) {
      this.selectedRole = role;

      if (role) {
        try {
          if (role.mapa && role.mapa !== 'null') {
            this.permissionsMap = JSON.parse(role.mapa);
          } else {
            this.permissionsMap = this.createDefaultMap();
          }
          // Aseguramos que todos los valores sean números (0 o 1)
          Object.keys(this.permissionsMap).forEach(key => {
            this.permissionsMap[key] = this.permissionsMap[key] ? 1 : 0;
          });
          this.originalPermissionsMap = JSON.parse(JSON.stringify(this.permissionsMap));
        } catch (error) {
          console.error('Error al procesar el mapa de permisos:', error);
          this.permissionsMap = this.createDefaultMap();
          this.originalPermissionsMap = JSON.parse(JSON.stringify(this.permissionsMap));
        }
      } else {
        this.permissionsMap = {};
        this.originalPermissionsMap = {};
      }
    },

    async obtenerPersonas(personaId) {
      try {
        let res = await this.$store.getters.fetchGet({
          path: "Persona/GetPersonaById/" + personaId,
        });
        personaId = await res.json();
        console.log("informacion", personaId);
        this.updateSelectedRole(personaId);
      } catch (error) {
        Swal.fire({
          title: "Error",
          text: "Por favor recarga la pagina",
          icon: "error",
          confirmButtonText: "Voy a recargar la pagina",
        });
        //this.Persona = {};
        console.log("informacion erroroaoaoao", error);
      }
    },

    createDefaultMap() {
      const defaultMap = {};

      this.data.registroJson.data.forEach(group => {
        group.subGrupo.forEach(subGroup => {
          subGroup.items.forEach(item => {
            const mainPath = this.createPermissionPath(item.ruta);
            defaultMap[mainPath] = 0;

            if (item.subItems) {
              item.subItems.forEach(subItem => {
                const subPath = this.createPermissionPath(subItem.ruta);
                defaultMap[subPath] = 0;
              });
            }
          });
        });
      });

      return defaultMap;
    },

    getPermissionStatus(itemRoute, subItemRoute = null) {
      const path = subItemRoute ? subItemRoute : itemRoute;
      return Boolean(this.permissionsMap[path]);
    },

    togglePermission(itemRoute, value, subItemRoute = null) {
      const path = subItemRoute ? subItemRoute : itemRoute;
      this.$set(this.permissionsMap, path, value ? 1 : 0);

      const currentItem = this.findItem(itemRoute);
      if (currentItem && currentItem.subItems && !subItemRoute) {
        currentItem.subItems.forEach(subItem => {
          const subPath = subItem.ruta;
          this.$set(this.permissionsMap, subPath, value ? 1 : 0);
        });
      }
    },


    // Método auxiliar para encontrar un item específico
    findItem(itemRoute) {
      for (const group of this.data.registroJson.data) {
        for (const subGroup of group.subGrupo) {
          const item = subGroup.items.find(i => i.ruta === itemRoute);
          if (item) return item;
        }
      }
      return null;
    },
    async saveData() {
      if (!this.selectedRole) {
        this.showSweetAlert(
          "Error",
          "Por favor, selecciona una persona antes de guardar.",
          "error"
        );
        return;
      }

      if (JSON.stringify(this.permissionsMap) === JSON.stringify(this.originalPermissionsMap)) {
        this.showSweetAlert('Información', 'No se han realizado cambios en los permisos.', 'info');
        return;
      }

      try {
        const PersonaId = this.selectedRole.id;
        const mapa = JSON.stringify(this.permissionsMap);

        const response = await this.$store.dispatch("hl_post", {
          path: `Persona/UpdateMapaPersona/${PersonaId}`,
          method: "POST",
          data: mapa
        });

        console.log("response", response);

        if (response) {
          this.showSweetAlert(
            "Éxito",
            "Los permisos de la persona han sido actualizados correctamente.",
            "success"
          );
          this.originalPermissionsMap = JSON.parse(JSON.stringify(this.permissionsMap));
        } else {
          throw new Error("La respuesta del servidor no fue exitosa");
        }
      } catch (error) {
        console.error("Error al guardar los cambios:", error);
        this.showSweetAlert(
          "Error",
          "Hubo un problema al guardar los cambios. Por favor, intenta de nuevo.",
          "error"
        );
      }
    },
    cancelar() {
      this.permissionsMap = JSON.parse(JSON.stringify(this.originalPermissionsMap));
      this.selectedRole = null;
      this.$router.push('/helex/persona');
    },

    showSweetAlert(title, text, icon) {
      Swal.fire({
        title: title,
        text: text,
        icon: icon,
        showCancelButton: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'OK'
      });
    },

    manipularTitulos(title, index) {
      return `${title.toLowerCase().replace(/[^a-z0-9]+/g, "-")}-${index}`;
    },

    updateWindowSize() {
      this.responsive = window.innerWidth > 600;
    },

    colapsar(subGroupIndex) {
      this.$set(this.colapsado, subGroupIndex, !this.colapsado[subGroupIndex]);
    },

    validarSuperAdmin(item) {
      if (!item) {
        return true;
      }

      if (this.usuario.rol.nivel == 4) {
        console.log("Este usuario es Super Admin", this.usuario.rol.nivel);
        return true;
      }
      return false;
    },
  },

};


</script>

<style scoped>
.card-body {
  padding: 0px !important;
}

.permission-manager {
  max-width: 1200px;
  margin: 0 auto;
}

.selected-person-info {
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
}

.b-tabs.card.vertical {
  border: none;
}

.tab-content {
  padding-left: 20px;
}

.list-group-item {
  transition: background-color 0.2s ease;
}

.list-group-item:hover {
  background-color: #f8f9fa;
}

.btn-group-sm>.btn {
  padding: 0.25rem 0.5rem;
}

.badge {
  font-size: 0.9em;
}

.mapa-acceso-container {
  max-height: 200px;
  overflow-y: auto;
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 5px;
}

.mapa-acceso {
  white-space: pre-wrap;
  word-wrap: break-word;
  padding: 10px;
  margin: 0;
}
</style>
